export default [
    {
        path: '/auth/login',
        name: 'Login',
        component: () => import('@/views/auth/Login'),
        meta: { title: '登录'}
    },
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home'),
        meta: { title: '查询设备'}
    },
    {
        path: '/device/carlife&command',
        name: 'CarLife&Command',
        component: () => import('@/views/device/CarLife'),
        meta: { title: '查询设备&执行命令'}
    },
    {
        path: '/scan',
        name: 'Scan',
        component: () => import('@/views/device/Scan'),
        meta: { title: '扫一扫' }
    },
    {
        path: '/404',
        name: 'PageNotFund',
        component: () => import('@/views/error/404'),
        meta: { title: '页面不存在'}
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    }
]
