<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 1rem;*/
  /*max-width: 2160px;*/
  /*max-height: 4096px;*/
  /*min-height: 640px;*/
  /*min-width: 320px;*/
  overflow: hidden;
  /*width: 100%;*/
  top: 0;
  bottom: 0;
}
</style>
