import { createApp } from 'vue'
import App from './App.vue'
import Vant from "vant"
import 'vant/lib/index.css'
import axios from "axios"
import VueAxios from "vue-axios"
import store from "@/store"
import router from "@/router"

const app = createApp(App)

app.use(Vant)
app.use(VueAxios, axios)
app.use(store)
app.use(router)


app.mount('#app')
