import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import store from "@/store";

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const token = store.state.token
    const expired_at = store.state.expired_at
    const now = Math.ceil(+new Date() / 1000)
    // 目标页标题
    if (to.meta.title) {
        document.title = to.meta.title
    }
    // 未认证跳转登录页面
    if ((!token || expired_at <= now) && to.name !== 'Login') {
        next('/auth/login')
    }
    // 防止手动触发登录页面
    if (token && expired_at > now && to.name === 'Login') {
        next('/')
    }
    next()
})

export default router
