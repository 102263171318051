import { createStore } from 'vuex'
import ls from '@/utils/localStorage'
import router from "@/router"

const tokenKey = 'me_token_key'
const refreshKey = 'me_refresh_key'
const expiredKey = 'me_expired_time_key'
const userInfoKey = 'me_userinfo_key'

const store = createStore({
    state () {
        return {
            user:ls.getItem(userInfoKey),
            // auth: ls.getItem('auth'),
            token: ls.getItem(tokenKey),
            expired_at: ls.getItem(expiredKey),
            refresh_token: ls.getItem(refreshKey)
        }
    },
    mutations: {
        update_user (state, user) {
            state.user = user
            ls.setItem(userInfoKey, user)
        },
        update_auth (state, auth) {
            // access_token : (Bearer xxx) 认证 token
            // refresh_token 刷新 token
            // expired_at 过期时间
            const token = auth.token_type + ' ' + auth.access_token
            const refresh_token = auth.refresh_token
            const expired_at = auth.expires_in + Math.ceil(+new Date() / 1000)
            state.token = token
            state.refresh_token = refresh_token
            state.expired_at = expired_at
            ls.setItem(tokenKey, token)
            ls.setItem(refreshKey, refresh_token)
            ls.setItem(expiredKey, expired_at)
        },
        logout (state) {
            state.token = ''
            state.refresh_token = ''
            state.expired_at = ''
            ls.removeItem(tokenKey)
            ls.removeItem(refreshKey)
            ls.removeItem(expiredKey)
            ls.removeItem(userInfoKey)
        }
    },
    actions: {
        login ({ commit }, data) {
            commit('update_auth', data)
            router.push({ path: '/'})
        },
        logout ({ commit }) {
            commit('logout', false)
            router.push('/auth/login')
        },
        update_user_info ({ commit }, data) {
            commit('update_user', data)
        }
    }
})

export default store
